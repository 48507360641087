/*
    Canopy Authentication module
*/
frappe.provide("client_portal.fenris_canopy_lookup");

client_portal.fenris_canopy_lookup.CanopyAuth = class CanopyAuth{

    constructor(args){
        $.extend(this, args);
        this.api = new client_portal.fenris_canopy_lookup.CanopyAPI();
        this.make();

    }

    make(){
        this.$wrapper = this.get_wrapper();
        this.init_handlers();
    }

    open(){
        this.form.$parent.addClass("d-none");
        this.reset_values();
        this.$wrapper.removeClass("d-none");
    }

    reset_values(){
        this.$login_or_signup_checkbox_wrapper.prop("checked", false);
        this.$login_or_signup_checkbox_wrapper.val("");
    }

    close(set_insurance_field_value){
        this.$wrapper.addClass("d-none");
        this.form.$parent.removeClass("d-none");
        if(this.dialog_secondary_action_handler){
            this.dialog_secondary_action_handler(set_insurance_field_value);
        }
    }

    init_handlers(){
        this.handle_on_close();
        this.handle_privacy_policy_step();
        this.handle_select_carrier_step();
        this.handle_login_or_signup_step();
        this.handle_auth_step();
        this.setup_mfa_step();
    }

    handle_privacy_policy_step(){
        this.$privacy_policy_wrapper = this.$wrapper.find(".privacy-and-policy-step");
        this.$privacy_policy_wrapper.find("input").on("click", (e)=>{
            e.preventDefault();
            this.$privacy_policy_wrapper.addClass("d-none");
            this.$select_carrier_wrapper.removeClass("d-none");
            return false;
        });
    }

    handle_select_carrier_step(){
        this.carriers = {};
        this.selected_carrier = {};
        this.$select_carrier_wrapper = this.$wrapper.find(".select-carrier-step");
        this.$carrier_search_field = this.$select_carrier_wrapper.find(".connect-form-search");
        this.$carrier_search_field.niceSelect();
        this.$carrier_search_field.on("change", (e)=>{
            let $temp = $(e.target);
            $temp.next().removeClass("text-muted").addClass("select-active");
            $temp.parent().addClass("select-active");
            let carrier_detail = this.carriers[$temp.val()];
            this.$login_or_signup_wrapper.find(".carrier-back-title").text(carrier_detail.name);
            let color = carrier_detail.color;
            this.$auth_wrapper.css("background-color", color);
            this.$auth_wrapper.find(".auth-step-carrier-name").html(`<p class="connect-form-title coolvetica-font">
                    ${carrier_detail.name}</br>
                <span class="connect-form-subtitle">${carrier_detail.homepageUrl}</span>
            </p>`);
            this.$login_or_signup_wrapper.css("background-color", color);
            this.selected_carrier = carrier_detail;
            this.$select_carrier_wrapper.find("input[name='next']")
                .removeClass("disabled")
                .removeAttr("disabled")
                .addClass("active");
            this.setup_thirdplaceholder();
            this.$login_or_signup_main_title.text(`Do you already have an online account with ${carrier_detail.name}?`);
            this.reset_values();

        });
        this.api.get_carriers().then((carriers)=>{
            this.$carrier_search_field.find("option").remove();
            $(`<option disabled selected>Select a carrier</option>`).appendTo(this.$carrier_search_field);
            if(carriers && carriers.length >= 1){
                carriers.forEach((carrier)=>{
                    this.carriers[carrier.carrier_id] = carrier;
                    $(`<option value='${carrier.carrier_id}'>${carrier.name}</option>`)
                                    .appendTo(this.$carrier_search_field);
                });
            }
            this.$carrier_search_field.niceSelect("update");
        });
        this.$select_carrier_wrapper.find("input[name='next']").on("click", (e)=>{
            if(!this.selected_carrier){
                return false;
            }
            this.$select_carrier_wrapper.addClass("d-none");
            this.$login_or_signup_wrapper.removeClass("d-none");
        });

        this.$select_carrier_wrapper.find("button[name='back']").on("click", (e)=>{
            e.preventDefault();
            this.$select_carrier_wrapper.addClass("d-none");
            this.$privacy_policy_wrapper.removeClass("d-none");
            return false;
        });
    }


    handle_login_or_signup_step(){
        this.$login_or_signup_wrapper = this.$wrapper.find(".login-or-signup-step");
        this.$login_or_signup_checkbox_wrapper = this.$wrapper.find("input[name='have-account-with-carrier']");
        this.$login_or_signup_main_title = this.$login_or_signup_wrapper.find(".connect-form-title")
        this.$login_or_signup_wrapper.find(".go-back-btn").on("click", (e)=>{
            e.preventDefault();
            this.$login_or_signup_wrapper.addClass("d-none");
            this.$select_carrier_wrapper.removeClass("d-none");
            return false;
        });

        this.$login_or_signup_checkbox_wrapper.on("change", (e)=>{
           let val = $(e.currentTarget).val();
           
            if(this.selected_carrier){
                if(val == "Yes"){
                    // pass for now
                }else if(! is_null(val)){
                    window.open(this.selected_carrier.createAccountUrl, "_blank");
                }
            }
           this.$login_or_signup_wrapper.addClass("d-none");
           this.$auth_wrapper.removeClass("d-none");
        });
    }

    handle_auth_step(){
        this.$auth_wrapper = this.$wrapper.find(".canopy-auth-step");
        this.$auth_wrapper.find(".go-back-btn").on("click", (e)=>{
            e.preventDefault();
            this.$login_or_signup_wrapper.removeClass("d-none");
            this.$auth_wrapper.addClass("d-none");
            this.reset_values();
            return false;
        });

        this.$auth_wrapper.find(".auth-step-create-account").on("click", (e)=>{
            e.preventDefault();
            if(this.selected_carrier){
                window.open(this.selected_carrier.createAccountUrl, "_blank");
            }
            return false;
        });

        this.$auth_wrapper.find(".auth-step-forgot-password").on("click", (e)=>{
            e.preventDefault();
            if(this.selected_carrier){
                window.open(this.selected_carrier.resetPasswordUrl, "_blank");
            }
            return false;
        });

        this.$auth_wrapper.find(".auth-step-create-account").on("click", (e)=>{
            e.preventDefault();
            if(this.selected_carrier){
                window.open(this.selected_carrier.createAccountUrl, "_blank");
            }
            return false;
        });

        this.$wrapper.find("#msform").on("submit", (e)=>{
            e.preventDefault();
            return false;
        });

        this.$auth_wrapper.find(".auth-step-login").on("click", (e)=>{
            e.preventDefault();
            let selected_carrier = this.selected_carrier;
            let usr = this.$auth_wrapper.find(".auth-step-usr").val();
            let pwd = this.$auth_wrapper.find(".auth-step-pwd").val();
            
            if(!this.validate_third_field_placeholder()){
                return false;
            }
            if(!(usr && pwd)){
                return false;
            }
            let user_credentials = {
                usr: usr,
                pwd: pwd,
                insurerThirdField: this.$third_field_input.val(),
                selected_carrier: selected_carrier,
                meta_data:{
                    form_key: this.form.get_abandoned_form_key(),
                    form_type: this.form.doctype,
                }
            };
            this.clear_error();
            this.api.get_data("send_auth_request", user_credentials, true, "Validating credentials...").then((consent_data)=>{
                if(consent_data && !is_null(consent_data.error)){
                    this.display_error_message(consent_data.error, "CONSENT_AND_CONNECT_ERROR");
                }else{
                    consent_data.meta_data = user_credentials.meta_data;
                    this.api.get_data("pull_user_data", consent_data, true, consent_data.status).then((pulled_data)=>{
                        if(pulled_data && in_list(["PROCESSING", "GETTING_CONSUMERS", "PULLING_DATA", "SUCCESS"], pulled_data.status)){
                            this.get_and_show_data(consent_data)
                        }else if(pulled_data && pulled_data.status == "IDENTITY_VERIFICATION_OPTIONS"){
                            this.display_mfa_step(consent_data, pulled_data);
                            // check erros or second step authentication
                        }else if(pulled_data && pulled_data.status == "NOT_AUTHENTICATED"){
                            this.display_error_message(pulled_data.status, "PULL_ERROR", pulled_data);
                        }else if(pulled_data && pulled_data.error){
                            this.display_error_message(pulled_data.error, "PULL_ERROR");
                        }
                    });
                }
            });
            return false;
        });
    }

    redirect_user_to_login_page_with_error(error){
        this.display_error_message(error, "MFA_ERROR");
        this.$mfa_step_wrapper.addClass("d-none");
        this.$auth_wrapper.removeClass("d-none");
        this.$mfa_auth_option_select.val('');
        this.$mfa_auth_option_select.niceSelect('update');
        this.$mfa_auth_option_select.next().removeClass("disabled");
        this.$mfa_step_wrapper.find(".mfa-input-label").empty();
        this.$mfa_auth_field.find("input").val('');
        this.$mfa_auth_field.addClass("d-none").removeClass("d-flex");
        this.$mfa_submit
            .addClass("disabled")
            .attr("disabled", true)
            .removeClass("active");
    }

    setup_mfa_step(){
        this.$mfa_step_wrapper = this.$wrapper.find(".mfa-auth-step");
        this.$mfa_submit = this.$mfa_step_wrapper.find("input[type='submit']");
        this.$mfa_back_btn = this.$mfa_step_wrapper.find("button[name='back']");
        this.$mfa_auth_option_select = this.$mfa_step_wrapper.find(".mfa-auth-step-select");
        this.$mfa_auth_field = this.$mfa_step_wrapper.find(".mfa-auth-field");
        this.$mfa_auth_option_select.niceSelect();
    }
    display_mfa_step(consent_data, pulled_data){
        this.$mfa_step_wrapper.css("background-color", this.selected_carrier.color);
        this.$auth_wrapper.addClass("d-none");
        this.$mfa_step_wrapper.removeClass("d-none");
        
        let mfa_options = [{
            "value": "",
            "label": __("Select a option"),
        }];
        $.each(pulled_data.mfa_options, (key, val)=>{
            mfa_options.push({
                value: key,
                label: val
            });
        })

        this.$mfa_auth_option_select.find("option").remove();
        let $opt;
        mfa_options.forEach((option, idx)=>{
            $opt = $(`<option value='${option.value}'>${option.label}</option>`).appendTo(this.$mfa_auth_option_select);
            if(idx === 0){
                $opt.attr("disabled", true).attr("selected", true).attr("value", "");

            }
        });
        this.$mfa_auth_option_select.niceSelect("update");
        this.$mfa_submit.off("click");
        let code_sent = false;
        this.$mfa_step_wrapper.find(".mfa-input-label").empty();
        this.$mfa_submit.on("click", (e)=>{
            let mfa = this.$mfa_auth_field.find("input").val();
            if(!mfa){
                return false;
            }
            let mfa_idv_data = {
                pull_id: consent_data.pull_id,
                pull_jwt: consent_data.pull_jwt,
                mfaValue: mfa
            };
            this.api.get_data("send_idv_mfa", mfa_idv_data, true, __("Please wait while we're validing your input...")).then((mfa_response_data)=>{
                if(mfa_response_data && mfa_response_data.error){
                    if(mfa_response_data.error === "INVALID_ACTION"){
                        this.$mfa_auth_option_select.val('');
                        this.$mfa_auth_option_select.niceSelect('update');
                        this.$mfa_auth_option_select.next().removeClass("disabled");
                        this.$mfa_step_wrapper.find(".mfa-input-label").empty();
                        this.$mfa_auth_field.find("input").val('');
                        this.$mfa_auth_field.addClass("d-none").removeClass("d-flex");
                        this.$mfa_submit
                            .addClass("disabled")
                            .attr("disabled", true)
                            .removeClass("active");
                        this.redirect_user_to_login_page_with_error(mfa_response_data.error);
                    }else{
                        this.display_error_message(mfa_response_data.error, "MFA_ERROR", {value: mfa, label: pulled_data.mfa_options[mfa]});
                    }

                }else{
                    code_sent = true;
                    this.api.get_data("pull_user_data", mfa_idv_data, true, "Please wait while we're validating your input.").then((mfa_pulled_data)=>{
                        if(mfa_pulled_data && mfa_pulled_data.status === "NOT_AUTHENTICATED"){
                            this.redirect_user_to_login_page_with_error(mfa_pulled_data.status);
                        }else{
                            this.get_and_show_data(consent_data);
                        }
                    });
                }
            });
            return false;
        });

        // handle backclick
        this.$mfa_auth_field.off("click");
        this.$mfa_back_btn.on("click", (e)=>{
            this.$mfa_step_wrapper.addClass("d-none");
            this.$auth_wrapper.removeClass("d-none");
            return false;
        });
        this.$mfa_auth_option_select.off("change");
        this.$mfa_auth_option_select.next().removeClass("disabled");
        this.$mfa_auth_option_select.on("change", (e)=>{
            let val = $(e.target).val();
            let data = {
                pull_id: consent_data.pull_id,
                pull_jwt: consent_data.pull_jwt,
                optionKey: val,
            }
            if(!is_null(val)){
                this.api.get_data("send_idv_options", data, true, `...`).then((idv_response_data)=>{
                    let label = pulled_data.mfa_options[val];
                    let mfa_input_type = idv_response_data.mfa_input_type;
                    if(idv_response_data && idv_response_data.pull_id){
                        this.$mfa_auth_field.removeClass("d-none").addClass("d-flex");
                        this.$mfa_submit
                            .removeClass("disabled")
                            .removeAttr("disabled")
                            .addClass("active");
                    }
                    let msg = "";
                    if(mfa_input_type == "SMS_OTP"){
                        msg = `You should receive a text message with your code at this number: ${label.replace("Text ", "")}.`;
                    }else if(mfa_input_type == "OTHER_OTP"){
                        msg = "You should receive a code via: Carrier App Security Code.";
                    }else if(mfa_input_type == "VOICE_OTP"){
                        msg = `You should receive a call with your code at this number: ${label.replace("Call ", "")}.`;
                    }else if(mfa_input_type ==  "SECURITY_QUESTION"){
                        msg = `Please answer the following security question: <br>${idv_response_data.mfa_security_question}`
                    }else if(mfa_input_type == "EMAIL_OTP"){
                        msg = `You should receive an email with your code at this email address: ${label.replace("Email ", "")}.`;
                    }else if(mfa_input_type == "CARRIER_PIN"){
                        msg = `Enter your ${this.selected_carrier.name} PIN`;
                    }
                    this.$mfa_auth_option_select.next().addClass("disabled");
                    this.$mfa_step_wrapper.find(".mfa-input-label").html(`<p class="mt-2 text-center text-white">${msg}</p>`);
                });
            }
        });
    }

    setup_thirdplaceholder(){
        this.$thirdfield_placeholder = this.$wrapper.find(".auth-thirdfieldplaceholder-item");
        this.$third_field_input = this.$thirdfield_placeholder.find("input");
        let selected_carrier = this.selected_carrier;
        if(selected_carrier && this.selected_carrier.thirdFieldPlaceholder){
            this.$thirdfield_placeholder
                .removeClass("d-none")
                .addClass("d-flex");
            this.$third_field_input.attr("placeholder", this.selected_carrier.thirdFieldPlaceholder)
                                    .attr("required", true);
        }else{
            this.$thirdfield_placeholder
                .addClass("d-none")
                .removeClass("d-flex");
            this.$third_field_input.removeAttr("placeholder").removeAttr("required");
        }
    }

    validate_third_field_placeholder(){
        if(this.$third_field_input.is(":visible") && ! this.$third_field_input.val()){
            return false;
        }
        return true;
    }

    handle_on_close(){
        this.$close_btn = this.$wrapper.find(".popup-close-btn");
        this.$close_btn.on("click", (e)=>{
            e.preventDefault();
            this.close(true);
            return false;
        });
    }

    get_and_show_data(pull_info){
        // show data in canopy
        this.api.get_data("get_policy_data", pull_info, true, "Pulling data....").then((data)=>{
            if(data.internal_error){
                frappe.msgprint(__("Internal error"));
                return;
            }
            // show data in canopy
            if(this.dialog_primary_action_handler){
                this.close(false);
                this.dialog_primary_action_handler(data);
            }

        });
        
    }

    display_error_message(error_code, error_from, data){
        let $wrapper = this.$auth_wrapper;
        let selected_carrier = this.selected_carrier;
        let error = "The username and password you entered is incorrect. Please signup or reset the password.";
        if(error_from == "CONSENT_AND_CONNECT_ERROR"){
            
        }
        this.$wrapper.find(".error-msg")
            .addClass("d-none")
            .removeClass("d-flex")
            .empty();

        if(error_code == "INVALID_THIRDFIELD"){
            error = __("The {0} you entered is incorrect. Please try again.", [selected_carrier.thirdFieldPlaceholder.toLowerCase()]);
        }else if(error_code == "INVALID_PASSWORD"){
            error = __("The password you entered is incorrect. Please reset the password & try again.");
        }else if(error_code == "NOT_AUTHENTICATED"){

            error = __("The username and password you entered is incorrect. Please signup or reset the password.");
            if(error_from == "PULL_ERROR"){
                if(data && data.login_error_message){
                    error = data.login_error_message;
                }
            }else if(error_from == "MFA_ERROR"){
                error = `The verification code you entered is incorrect or expired. Please try again.`;
            }
        }else if(error_code == "INVALID_ACTION"){
            error = "The verification code you entered is incorrect or expired. Please try again.";
        }
        $wrapper.find(".error-msg")
                .removeClass("d-none")
                .addClass("d-flex")
                .html(`<p class="mt-2 text-white">${error}</p>`);
                
    }

    clear_error(){
        this.$wrapper.find(".error-msg")
            .addClass("d-none")
            .removeClass("d-flex")
            .empty();
    }

    get_wrapper(){
        let $wrapper = $(".canopy-auth-wrapper");
        if(!$wrapper.length){
            $wrapper = $(`<main class="connect-form-main canopy-auth-wrapper d-none">
                <div class="connect-form">
                    <form id="msform">
                        <div id="connect-form-item-1" class="connect-form-item position-relative privacy-and-policy-step">
                            <div class="connect-form-content">
                                <div class="popup-close position-absolute">
                                    <button id="connect-close-1" class="btn popup-close-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                            viewBox="0 0 10 11">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                                                    </g>
                                                    <g transform="rotate(91 5.095 5.57)">
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-center text-center">
                                    <img src="/assets/bond_assets/images/bond-conect-illustration.svg" alt=""
                                        class="img-fluid d-none d-sm-inline">
                                    <img src="/assets/bond_assets/images/bond-conect-illustration-mob.svg" alt=""
                                        class="img-fluid d-sm-none">
                                </div>

                                <div class="d-flex justify-content-center text-center">
                                    <p class="connect-form-title coolvetica-font">Connect to your insurance provider now.</p>
                                </div>

                                <div class="d-flex justify-content-between flex-wrap connect-sp">
                                    <div class="connect-form-sp">
                                        <div class="connect-form-sp-wrap d-flex align-items-start">
                                            <img src="/assets/bond_assets/images/icons/icon-connect-secure.svg" alt=""
                                                class="img-fluid">
                                            <div class="connect-form-sp-text">
                                                <p class="coolvetica-font">Secure</p>
                                                <p>Transfer of your information
                                                    is encrypted end-to-end</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="connect-form-sp">
                                        <div class="connect-form-sp-wrap d-flex align-items-start">
                                            <img src="/assets/bond_assets/images/icons/icon-connect-private.svg" alt=""
                                                class="img-fluid">
                                            <div class="connect-form-sp-text">
                                                <p class="coolvetica-font">Private</p>
                                                <p>We will not have access</br>
                                                    to your login credentials</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="connect-form-next d-flex justify-content-center">
                                    <input type="button" name="next" class="next btn btn-dark btn-xl mt-3 mt-md-0 active"
                                        value="Continue">
                                </div>

                                <div class="d-flex justify-content-center text-center">
                                    <p class="connect-form-text">By selecting “Continue” you agree to the Canopy End Users <a href="/">Privacy
                                        Policy</a>.</p>
                                </div>
                            </div>
                        </div>

                        <div id="connect-form-item-2" class="d-none connect-form-item position-relative select-carrier-step">
                            <div class="connect-form-content">
                                <div class="popup-close position-absolute">
                                    <button id="connect-close-1" class="btn popup-close-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                            viewBox="0 0 10 11">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                                                    </g>
                                                    <g transform="rotate(91 5.095 5.57)">
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-center text-center">
                                    <img src="/assets/bond_assets/images/bond-conect-illustration.svg" alt=""
                                        class="img-fluid d-none d-sm-inline">
                                    <img src="/assets/bond_assets/images/bond-conect-illustration-mob.svg" alt=""
                                        class="img-fluid d-sm-none">
                                </div>

                                <div class="d-flex justify-content-center text-center">
                                    <p class="connect-form-title coolvetica-font">Select your current insurance provider</p>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <select class="connect-form-search wide text-muted nice-select-custom"></select>
                                </div>
                                <div class="justify-content-center d-none error-msg"></div>
                                <div class="d-flex justify-content-center">
                                    <div class="connect-form-next text-center position-relative col-12">
                                        <div class="form-disabled" style="height: 0px;"></div>
                                        <button name="back" class="back btn btn-lg">Back</button>
                                        <input  type="button" name="next" class="next-no-fade btn next btn-dark btn-lg disabled" disabled value="Continue">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="connect-form-item-3" class="d-none connect-form-item position-relative login-or-signup-step">
                            <div class="connect-form-content">
                                <div class="connect-back-btn">
                                    <button id="back-connect-3" class="d-flex align-items-center go-back-btn">
                                            <span class="icon d-flex justify-content-center align-items-center">
                                                <svg id="SVGDoc" width="9" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 10"> <g><g><path d="M7.90051,3.33051c0.922,0.60803 1.177,1.84906 0.56897,2.77106c-0.14905,0.22595 -0.34302,0.41895 -0.56897,0.56799l-4.22107,2.78497c-0.922,0.60699 -2.16296,0.35199 -2.77002,-0.57001c-0.21497,-0.32697 -0.32898,-0.70898 -0.32996,-1.099v-5.57098c0.00098,-1.10498 0.89697,-2 2.00195,-1.99902c0.39001,0 0.77209,0.11499 1.09802,0.33002z" fill="#f96632" fill-opacity="1"></path></g></g></svg>
                                            </span>
                                        <span class="label carrier-back-title go-back-btn">Travelers</span>
                                    </button>
                                </div>
                                <div class="popup-close position-absolute">
                                    <button id="connect-close-1" class="btn popup-close-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                            viewBox="0 0 10 11">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                                                    </g>
                                                    <g transform="rotate(91 5.095 5.57)">
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-center text-center">
                                    <p class="connect-form-title coolvetica-font"></p>
                                </div>
                                <div class="connect-radio btn-group-toggle btn-group-lg d-flex justify-content-center w-100"
                                    data-toggle="buttons">
                                    <label id="submit-connect-4-1" class="d-flex justify-content-between align-items-center btn btn-outline-warning cr-1 mr-2 mr-sm-3">
                                        <input type="radio" name="have-account-with-carrier" value="Yes">
                                        <span class="label-placeholder">Yes</span>
                                        <span class="circle-icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                    width="14"
                                                                    height="14" viewBox="0 0 14 14"><g><g><path
                                                fill="currentColor"
                                                d="M10.848 8.608l.472 2.752a.953.953 0 0 1-1.383 1l-2.472-1.3a.947.947 0 0 0-.887 0l-2.47 1.3a.952.952 0 0 1-1.384-1l.472-2.752a.955.955 0 0 0-.275-.844l-2-1.948a.953.953 0 0 1 .527-1.627l2.763-.4a.958.958 0 0 0 .718-.522l1.235-2.5a.954.954 0 0 1 1.71 0l1.236 2.5a.95.95 0 0 0 .717.522l2.763.4a.955.955 0 0 1 .53 1.627l-2 1.948a.95.95 0 0 0-.274.844"/></g></g></svg></span>
                                    </label>
                                    <label id="submit-connect-4-2" class="d-flex justify-content-between align-items-center btn btn-outline-warning cr-2">
                                        <input type="radio" name="have-account-with-carrier" value="No">
                                        <span class="label-placeholder">No, Create Account</span>
                                        <span class="circle-icon"><svg id="SVGDoc" width="9" height="10"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 9 10"> <g><g><path d="M7.90051,3.33051c0.922,0.60803 1.177,1.84906 0.56897,2.77106c-0.14905,0.22595 -0.34302,0.41895 -0.56897,0.56799l-4.22107,2.78497c-0.922,0.60699 -2.16296,0.35199 -2.77002,-0.57001c-0.21497,-0.32697 -0.32898,-0.70898 -0.32996,-1.099v-5.57098c0.00098,-1.10498 0.89697,-2 2.00195,-1.99902c0.39001,0 0.77209,0.11499 1.09802,0.33002z" fill="#f96632" fill-opacity="1"></path></g></g></svg>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div id="connect-form-item-4" class="d-none connect-form-item position-relative canopy-auth-step">
                            <div class="connect-form-content">
                                <div class="connect-back-btn">
                                    <button id="back-connect-4" class="d-flex align-items-center go-back-btn">
                                            <span class="icon d-flex justify-content-center align-items-center">
                                                <svg id="SVGDoc" width="9" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 10"> <g><g><path d="M7.90051,3.33051c0.922,0.60803 1.177,1.84906 0.56897,2.77106c-0.14905,0.22595 -0.34302,0.41895 -0.56897,0.56799l-4.22107,2.78497c-0.922,0.60699 -2.16296,0.35199 -2.77002,-0.57001c-0.21497,-0.32697 -0.32898,-0.70898 -0.32996,-1.099v-5.57098c0.00098,-1.10498 0.89697,-2 2.00195,-1.99902c0.39001,0 0.77209,0.11499 1.09802,0.33002z" fill="#f96632" fill-opacity="1"></path></g></g></svg>
                                            </span>
                                        <span class="label">Back</span>
                                    </button>
                                </div>

                                <div class="popup-close position-absolute">
                                    <button id="connect-close-4" class="btn popup-close-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                            viewBox="0 0 10 11">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path fill="none" stroke="#ffffff" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                                                    </g>
                                                    <g transform="rotate(91 5.095 5.57)">
                                                        <path fill="none" stroke="#ffffff" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                                <div class="mt-4 d-flex justify-content-center text-center auth-step-carrier-name"></div>
                                <div class="connect-user d-flex justify-content-between">
                                    <div class="connect-user-item">
                                        <div class="connect-user-input position-relative">
                                                <span class="position-absolute">
                                                    <img src="/assets/bond_assets/images/icons/user-id-icon.svg" alt=""
                                                        class="img-fluid">
                                                </span>
                                            <input type="text" class="auth-step-usr" placeholder="User ID" required>
                                        </div>
                                    </div>
                                    <div class="connect-user-item">
                                        <div class="connect-user-input position-relative">
                                                <span class="position-absolute">
                                                    <img src="/assets/bond_assets/images/icons/user-pass-icon.svg" alt=""
                                                        class="img-fluid">
                                                </span>
                                            <input type="password" class="auth-step-pwd" style="-webkit-text-security: square;" class="" placeholder="Account Password" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="connect-user d-flex justify-content-between auth-thirdfieldplaceholder-item d-none">
                                    <div class="connect-user-item-palceholderfield">
                                        <div class="connect-user-input position-relative">
                                            <span class="position-absolute">
                                                <img src="/assets/bond_assets/images/icons/user-pass-icon.svg" alt=""
                                                    class="img-fluid">
                                            </span>
                                            <input type="text" class="auth-step-thirdfieldplaceholder" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="justify-content-center d-none error-msg"></div>
                                <div class="connect-submit d-flex justify-content-center">
                                    <input type="submit" name="submit" class="btn btn-xl btn-warning text-center px-0 auth-step-login" value="Submit">
                                </div>

                                <div class="connect-links d-flex align-items-center justify-content-center">
                                    <a href="#" class="auth-step-forgot-password">Reset Password</a>
                                    <span>   /   </span>
                                    <a href="#" class="auth-step-create-account">Create An Account</a>
                                    <img src="/assets/bond_assets/images/icons/white-arrow-right.svg" alt=""
                                        class="img-fluid">
                                </div>
                            </div>
                        </div>
                        <div id="connect-form-item-5" class="d-none connect-form-item position-relative mfa-auth-step">
                            <div class="connect-form-content">
                                <div class="popup-close position-absolute">
                                    <button id="connect-close-1" class="btn popup-close-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                            viewBox="0 0 10 11">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M1 1.143v0l6.657 7.196v0L9.193 10v0"></path>
                                                    </g>
                                                    <g transform="rotate(91 5.095 5.57)">
                                                        <path fill="none" stroke="#161617" stroke-linecap="round"
                                                            stroke-miterlimit="20" stroke-width="1.5"
                                                            d="M.74 1.55v0l7.079 6.535v0l1.634 1.508v0"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                                <div class="d-flex justify-content-center text-center">
                                    <p class="connect-form-title coolvetica-font">Select an option below for your security code</p>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <select class="mfa-auth-step-select wide text-muted nice-select-custom"></select>
                                </div>
                                <div class="justify-content-center mfa-input-label"></div>
                                <div class="mfa-user d-flex justify-content-between mfa-auth-field d-none">
                                    <div class="mfa-user-item-palceholderfield">
                                        <div class="mfa-user-input position-relative">
                                            <span class="position-absolute">
                                                <img src="/assets/bond_assets/images/icons/user-pass-icon.svg" alt=""
                                                    class="img-fluid">
                                            </span>
                                            <input type="text" class="auth-step-thirdfieldplaceholder" required>
                                        </div>
                                    </div>
                                </div>
                                <div class="justify-content-center d-none error-msg"></div>
                                <div class="d-flex justify-content-center">
                                    <div class="connect-form-next text-center position-relative col-12">
                                        <div class="form-disabled" style="height: 0px;"></div>
                                        <button name="back" class="back btn btn-lg">Back</button>
                                        <input  type="submit" name="submit" class="next-no-fade btn next btn-dark btn-lg disabled" disabled value="Submit">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>`).appendTo(".body-content");
        }
        return $wrapper;
    }
}

client_portal.fenris_canopy_lookup.CanopyAPI = class CanopyAPI{

    constructor(args){
        $.extend(this, args);
    }

    get_carriers(){
       return this.get_data("get_carriers");
    }

    get_data(method, data={}, freeze=false, freeze_message=""){
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: `bond_assets.vendor_api.canopy_connect.${method}`,
                args: {
                    data: data,
                },
                freeze: freeze,
                freeze_message: __(freeze_message),
                callback: (data)=>{
                    resolve(data.message);
                },
                error: ()=>{
                    reject();
                }
            });
        });
    }
}