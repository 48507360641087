/*
    Controller for canopy
*/
frappe.provide("client_portal.fenris_canopy_lookup");

import "./base_lookup";
import "./canopy_auth";

client_portal.fenris_canopy_lookup.BaseCanopyConnectController = class CanopyConnectController extends client_portal.fenris_canopy_lookup.BaseLookup{

    make(){
        super.make()
        this.application_data = {};
        this.third_party_data = {};
        this.policy = null;
        /*
        this.dialer = CanopyConnect.create({
            // [Optional string] to skip the first screen in the widget, you can supply a consent token. See the “Usage with Consent API” section for more details.
            //consentToken: "<CONSENT_TOKEN_OBTAINED_FROM_OUR_CONSENT_API>",
            publicAlias: "bond-insurance-brokerage-inc",
            onAuthenticationSuccess: (data) => {
              // [Optional callback] called when user is authenticated
              frappe.dom.freeze(__("Please wait while we're authenticating..."));
              this.get_data().then((data)=>{
                frappe.dom.unfreeze();
                this.show_data(data);
              });
            },
            onExit: (err, data) => {
              if(err){
                this.set_insurance_field_value("Continue to application");
              }
            },
            onDestroy: () => {
              // [Optional callback] called when you use the `handler.destroy()` method below
            },
            onSelectCustomInsuranceProvider: ({ providerName }) => {
            },
            pullMetaData: {
              form_key: this.form_key,
              service_type: this.service_type
            },
        });
        */
        this.dialer = new client_portal.fenris_canopy_lookup.CanopyAuth({
            form: this.form,
            canopy_controller: this,
            dialog_primary_action_handler: (data)=>{
                this.show_data(data);
            },
            dialog_secondary_action_handler: (is_parital_form)=>{
                if(is_parital_form){
                    this.set_insurance_field_value("Continue to application");
                }
            }
        });

        this.dialog = new frappe.ui.Dialog({
            size: "large",
            primary_action_label: __("Confirm"),
            primary_action: (data)=>{
                if(this.dialog_primary_action_handler){
                    this.dialog_primary_action_handler(data);
                }else{
                    this.dialog.hide();
                }
            },
            secondary_action_label: __("Cancel & Continue Application"),
            secondary_action: ()=>{
                if(this.dialog_secondary_action_handler){
                    this.dialog_secondary_action_handler();
                }
            },
        });
          
    }

    show(){
        this.dialer.open();
    }

    get_data(){
        return new Promise((resolve, reject)=>{
            let interval_id = setInterval(()=>{
                frappe.call({
                    method: "bond_assets.vendor_api.canopy_connect.get_client_data",
                    args:{
                        form_key: this.form_key,
                        service_type: this.service_type
                    },
                    callback: (res)=>{
                        if(res && res.message && res.message.first_name){
                            resolve(res.message);
                            clearInterval(interval_id);
                        }
                    },
                    error: ()=>{
                        reject()
                    }
                });
            }, 1000);
        });
    }

    show_data(data){
        this.data = data;
        this.$modal_body = this.dialog.$wrapper.find(".modal-body");
        this.dialog.$wrapper.find(".modal-title").addClass("form-font font-weight-normal field-label");
        this.$main_table = this.get_table_view(data);
        this.set_dialog_title(data);
        this.set_details(data);
        this.dialog.show();
        this.dialer.close();
    }

    get_policy_info (data, policy_type){
        let policy_info ={};
        if(data.policies){
            data.policies.forEach((val, idx)=>{
                if(val.policy_type  == policy_type){
                    policy_info = val;
                    return val;
                }
            });
        }
        return policy_info;
    }

    set_dialog_title(data){
        this.dialog.set_title(this.get_full_name(data));
    }

    get_table_view(data){
        return $(`<table class="table table-bordered">
            <thead>
                <th>Mobile No.</th>
                <th>Email</th>
            </thead>
            <tbody>
                <tr>
                    <td>${bond_assets.phonenumbers.format_to_local(cstr(data.mobile_phone))}</td>
                    <td>${data.account_email}</td>
                </tr>
            </tbody>
        </table>`).appendTo(this.$modal_body);
    }

    get_full_name(data){
        let name = data.first_name;
        if(data.middle_name){
            name = `${name} ${data.middle_name}`;
        }
        if(data.last_name){
            name = `${name} ${data.last_name}`;
        }
        return name;
    }

    set_insurance_field_value(value){
        let insurance_field = this.form.get_field("import_insurance", "Personal Auto");
        insurance_field.set_input(value);
    }
};


/*
    Controller for personal auto functionality
*/
client_portal.fenris_canopy_lookup.PersonalAutoCanopyConnectController = class PersonalAutoCanopyConnectController extends client_portal.fenris_canopy_lookup.BaseCanopyConnectController{
 
    constructor(args){
        super(args);
    }

    set_details(data){
        this.add_another_driver = false;
        this.add_another_vehicle = false;
        this.primary_vehicle = {};
        this.primary_driver = {};
        this.doc = {
            first_name: this.data.first_name,
            last_name: this.data.last_name,
            middle_name: this.data.middle_mame,
            mobile_phone: this.data.mobile_phone,
            email: this.data.email,
            personal_auto: {
                phone_number: this.data.phone_number,
            },
            policy_info: this.get_policy_info(data, "AUTO"),
            drivers: [],
            vehicles: [],
            data: this.data
        }
        this.policy = this.doc.policy_info;
        if(this.policy && this.policy.vehicles){
            $(`<p class="pb-2 form-font font-weight-normal field-label">Here's the list of Vehicle we've found associated with your policy.</p>`).appendTo(this.$modal_body);
            let $vehicles = $(`<table class="table table-bordered">
                <thead>
                    <th>Year</th>
                    <th>Make</th>
                    <th>Modal</th>
                    <th>Add to application</th>
                </thead>
                <tbody></tbody>
            </table>`).appendTo(this.$modal_body);
            $(`<p class="pb-2 form-font font-weight-normal field-label">Here's the list of drivers we've found associated with your policy.</p>`).appendTo(this.$modal_body);
            let $drivers = $(`<table class="table table-bordered">
                <thead>
                    <th>Full name</th>
                    <th>License no.</th>
                    <th>License state</th>
                    <th>Date of birth</th>
                    <th>Add to application</th>
                </thead>
                <tbody></tbody>
            </table>`).appendTo(this.$modal_body);
            this.get_vehicles().then((vehicles)=>{
                vehicles.forEach((vehicle, idx)=>{
                    $(`<tr>
                        <td>${vehicle.year}</td>
                        <td>${vehicle.make}</td>
                        <td>${vehicle.model}</td>
                        <td class="">
                            <input data-vehicle-id="${vehicle.vehicle_id}" class="add-vehicle-check" type="checkbox"/>
                        </td>
                    </tr>`).appendTo($vehicles.find("tbody"));
                });
                $vehicles.find(".add-vehicle-check").on("change", (e)=>{
                    this.update_vehicles();
                });
            });

            this.get_drivers().then((drivers)=>{
                drivers.forEach((driver, idx)=>{
                    $(`<tr>
                        <td>${this.get_full_name(driver)}</td>
                        <td>${driver.drivers_license}</td>
                        <td>${driver.drivers_license_state}</td>
                        <td>${driver.date_of_birth_str}</td>
                        <td class="">
                            <input data-driver-id="${driver.driver_id}" class="add-driver-check" type="checkbox"/>
                        </td>
                    </tr>`).appendTo($drivers.find("tbody"));
                });

                $drivers.find(".add-driver-check").on("change", (e)=>{
                    this.update_drivers();
                });
            });

        }
    }

    update_vehicles(){
        this.doc.vehicles = [];
        this.get_vehicles().then((vehicles)=>{
            $.each($(".add-vehicle-check"), (idx, $check_btn)=>{
                let data = $($check_btn).data();
                vehicles.forEach((vehicle)=>{
                    if(vehicle.vehicle_id == data.vehicleId){
                        this.doc.vehicles.push(vehicle);
                    }
                });
            });
        });
    }

    update_drivers(){
        this.doc.drivers = [];
        this.get_drivers().then((drivers)=>{
            $.each($(".add-driver-check"), (idx, $check_btn)=>{
                let data = $($check_btn).data();
                drivers.forEach((driver)=>{
                    if(driver.driver_id = data.driverId){
                        this.doc.drivers.push(driver);
                    }  
                });
            });
        });
    }

    get_vehicles(){
        return new Promise((resolve, reject)=>{
            let vehicles = [];
            this.policy.vehicles.forEach((vehicle)=>{
                vehicles.push(vehicle);
            });
            resolve(vehicles);
        });
    }

    get_drivers(){
        return new Promise((resolve, reject)=>{
            let drivers = [];
            this.policy.vehicles.forEach((vehicle)=>{
                if(vehicle.drivers){
                    vehicle.drivers.forEach((driver)=>{
                        drivers.push(driver);
                    });
                }
            });
            resolve(drivers);
        });
    }

    dialog_primary_action_handler(data){
        this.from_primary_action = true;
        this.form.third_party_data = this.doc;
        this.dialog.hide();
    }

    dialog_secondary_action_handler(data){
        if(!this.from_primary_action){
            this.form.third_party_data = {};
            this.set_insurance_field_value("Continue to application");
            this.from_primary_action = true;
        }
    }
};

/*
    Controller for homeowners
*/
client_portal.fenris_canopy_lookup.HomeownersCanopyConnectController = class HomeownersCanopyConnectController extends client_portal.fenris_canopy_lookup.BaseCanopyConnectController{

    constructor(args){
        super(args);
    }

    set_details(data){
        this.data = data;

    }

    dialog_primary_action_handler(data){
        // hide the dialog after processing.
        this.dialog.hide();
    }

    dialog_secondary_action_handler(data){
        this.dialog.hide();
    }
}