/*
    Fenris lookup API's
*/
frappe.provide("client_portal.fenris_canopy_lookup");
import "./base_lookup";

client_portal.fenris_canopy_lookup.BaseFenrisLookup = class FenrisLookup extends client_portal.fenris_canopy_lookup.BaseLookup{

    constructor(args){
        super({
            title: "We found the following vehicle associated with your address",
            subtitle: "Please select the one you'd like to insure"
        })
        $.extend(this, args);
    }

    make(){
        super.make();
    }

    show(filters){
        if(!this.dialog){
            this.make_dialog(filters);
        }
        this.get_data(filters).then((data)=>{
            this.show_data(data);
        });
    }

    make_dialog(filters){
        this.dialog = new frappe.ui.Dialog({
            size: "large",
            primary_action_label: __("Confirm"),
            primary_action: (data)=>{
                if(this.selected_drivers){
                    this.prompt_selected_driver_license_dialog(this.selected_drivers);
                    this.dialog.hide();
                }
                if(this.dialog_primary_action_handler){
                    this.dialog_primary_action_handler(data);
                }else{
                    this.dialog.hide();
                }
            },
            secondary_action_label: __("Cancel & Continue Application"),
            secondary_action: ()=>{
                if(this.dialog_secondary_action_handler){
                    this.dialog_secondary_action_handler();
                }
            },
        });
    }

    get_data(filters){
        let method = "get_auto_info";
        if(in_list(["Condo", "Homeowners", "Renters"], this.form.doctype)){
            method = "get_home_info";
        }
        return new Promise((resolve, reject)=>{
            frappe.call({
                method: "bond_assets.vendor_api.fenris_api.get_data",
                args: {
                    method_name: method,
                    data: filters
                },
                freeze: true,
                freeze_message: __("Please wait we're pulling your info..."),
                callback: (res)=>{
                    resolve(res.message);
                },
                error: (err)=>{
                    reject(err);
                }
            });
        });
    }

    show_data(data){
        this.data = {};
        this.doc = {
        };
        this.$wrapper = this.dialog.$wrapper.find(".modal-body");
        this.dialog.set_title(this.get_full_name(data));        
        if(data && data.vehicles){
            this.add_vehicles(data);
        }
        if(data && data.drivers){
            this.add_drivers(data);
        }

        this.dialog.show();
    }

    add_vehicles(data){
        this.selected_vehicles = [];
        $(`<p class="pb-2 form-font font-weight-normal field-label">
            Here's the list of Vehicle we've found associated with your policy.
        </p>`).appendTo(this.$wrapper);
        let $wrapper = $(`<table class="table table-bordered">
            <thead>
                <th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th>Add</th>
            </thead>
            <tbody></tbody>
        </table>`).appendTo(this.$wrapper);

        let $vehicle;
        data.vehicles.forEach((vehicle, idx)=>{
            $vehicle = $(`<tr>
                <td>${ vehicle.year}</td>
                <td>${ vehicle.make}</td>
                <td>${vehicle.model}</td>
                <td>
                    <input type="checkbox" class="add-vehicle-inpuit" vehicle-idx='${idx}' />
                </td>
            </tr>`).appendTo($wrapper.find("tbody"));
        });

        $wrapper.find("input").on("change", (e)=>{
            let $ele = $(e.target);
            let vehicle_idx = $ele.attr("vehicle-idx");
            if($ele.is(":checked")){
                this.selected_vehicles.push(vehicle_idx);
            }else{
                this.selected_vehicles.splice(this.selected_vehicles.indexOf(vehicle_idx));
            }
        });
    }

    add_drivers(data){
        this.selected_drivers = [];
        $(`<p class="pb-2 form-font font-weight-normal field-label">
            Here's the list of drivers we've found associated with your policy.
        </p>`).appendTo(this.$wrapper);

        let $wrapper = $(`<table class="table table-bordered">
            <thead>
                <th>Name</th>
                <th>Add</th>
            </thead>
            <tbody></tbody>
        </table>`).appendTo(this.$wrapper);

        let $row;
        data.drivers.forEach((driver, idx)=>{
            $row = $(`<tr>
                <td class='driver-first-name'>${driver.firstName} ${driver.lastName}</td>
                <td class='add-driver'>
                    <input type="checkbox" class='input-add-driver' driver-idx='${idx}'/>
                </td>
            </tr>`).appendTo($wrapper.find("tbody"));
        });

        $wrapper.find(".input-add-driver").on("change", (e)=>{
            let $ele = $(e.target);
            let driver_idx = $ele.attr("driver-idx");
            if($ele.is(":checked")){
                this.selected_drivers.push(driver_idx);
            }else{
                this.selected_drivers.splice(this.selected_drivers.indexOf(driver_idx));
            }
        });
    }

    get_full_name(data){
        let primary_driver = this.get_primary_driver(data);
        return primary_driver?`${primary_driver.firstName} ${primary_driver.lastName}`: '';
    }

    get_primary_driver(data){
        let primary_driver = {};
        if(data && data.drivers){
            primary_driver = data.drivers[0];
        }
        return primary_driver;
    }

    prompt_selected_driver_license_dialog(drivers){
        let dialog = new frappe.ui.Dialog({
            title: __("Enter License info for selected drivers"),
            primary_action:(doc)=>{

            },
            secondary_action: ()=>{

            }
        });

        let $wrapper = dialog.$wrapper.find(".modal-body");
        let $driver;
        let fields = [];
        drivers.forEach((driver_idx)=>{

            $driver = $(`<div>
                <div class="row full-name"></div>
                <div class="row license-number"></div>
                <div class="row license-state"></div>
                </hr>
            </div>`).appendTo($wrapper);

            fields.push({
                first_name: frappe.ui.form.make_control({df: {fieldtype: "Data", label: "Full Name", fieldname: "full_name"}, parent: $driver.find(".full-name"), render_input: true}),
                license_number: frappe.ui.form.make_control({df: {fieldtype: "Data", label: "License Number", fieldname: "license_number"}, parent: $driver.find(".license-number"), render_input: true}),
                license_state: frappe.ui.form.make_control({df: {fieldtype: "Select", label: "Select", options:["Select License Plate"], fieldname: "license_state"}, parent: $driver.find(".license-state"), render_input: true}),
            });
        });

        dialog.show();
    }
};


/*
    Fenris Personal Auto Lookup
*/

client_portal.fenris_canopy_lookup.PersonalAutoFenrisController = class PersonalAutoFenrisController extends client_portal.fenris_canopy_lookup.BaseFenrisLookup{

    make(){
        super.make();
    }

    set_details(data){
        this.data = data;
    }

    dialog_primary_action_handler(data){
        this.from_primary_action = true;
        this.dialog.hide();
    }

    dialog_secondary_action_handler(data){
        if(!this.from_primary_action){
        }
    }
}

/*
    Homeowners lookup
*/
client_portal.fenris_canopy_lookup.HomeownersFenrisController = class HomeownersFenrisController extends client_portal.fenris_canopy_lookup.BaseFenrisLookup{

}

