
frappe.provide("client_portal.fenris_canopy_lookup");


client_portal.fenris_canopy_lookup.BaseLookup = class FenrisCanopyBaseLookup{

    constructor(args){
        $.extend(args, {
            primary_action_title: __("Continue"),
            secondary_action_title: __("Close"),
            only_initiate: true,
            data: {},
        })
        $.extend(this, args);
        // only_initate flag indicate that popup won't open while initializing
        this.make();
    }

    make(){
        this.set_title(this.title);
        this.set_sub_title(this.subtitle);
    }

    trigger_show_eval(form, fieldobj){
        try{
            let fieldname = fieldobj && fieldobj.df ? fieldinfo.df.fieldname: null;
            if(this.trigger_on_fieldname == fieldname &&
                    !is_null(this.eval_expression)){
                let out = form.evaluate_depends_on_value(this.eval_expression);
                if(out){
                   this.show_data();
                }
            }
        }catch(e){
            console.log(e);
        }
    }

    show(data){
        // override this method in subclass
    }

    set_title(title){
        console.warn(__("Please setup this method in subclass"));
    } 
    
    set_sub_title(title){
        console.warn(__("Please setup this method in subclass"));
    }
}